import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map, switchMap } from 'rxjs';
import { DataService } from 'src/app/service/data.service';
import { environment } from 'src/environments/environment';
import { AccessProfile } from '../models/access-profile';

const BASE_URL = `${environment.mkgoURL}/api/v1/profile`;
// BASE_URL = 'http://localhost:3000/profile'; // using json-server
@Injectable({
  providedIn: 'root'
})
export class AccessProfileService {

  private _isFromMatrix = false;


  constructor(
    private _dataService: DataService,
    private _http: HttpClient
  ) { }


  getAll() {
    return this._dataService.httpOptions(this._isFromMatrix).pipe(
      switchMap(options => this._http.get<{ profiles: AccessProfile[] }>(BASE_URL, options)),
      first(),
      map(resp => resp.profiles)
    );
  }

  get(id: string) {
    return this._dataService.httpOptions(this._isFromMatrix).pipe(
      switchMap(options => this._http.get<AccessProfile>(`${BASE_URL}/${id}`, options)),
      first()
    );
  }

  create(profile: AccessProfile) {
    return this._dataService.httpOptions(this._isFromMatrix).pipe(
      switchMap(options => this._http.post(BASE_URL, profile, options)),
      first(),
    );
  }

  update(profile: AccessProfile) {
    const apiObject = {
      name: profile.name,
      modules: profile.modules
    }
    return this._dataService.httpOptions(this._isFromMatrix).pipe(
      switchMap(options => this._http.put<{ id: string }>(`${BASE_URL}/${profile._id}`, apiObject, options)),
      first(),
    );
  }

  delete() {

  }


}
